<template>
  <el-select
    v-model="localValue"
    :size="size"
    :multiple="multiple"
    remote
    clearable
    :filterable="filterable"
    reserve-keyword
    :placeholder="placeholder"
    :remote-method="remoteMethod"
    @visible-change="$event ? remoteMethod() : null"
    :loading="loading"
  >
<!--    <div-->
<!--      v-loading="loading"-->
<!--      v-infinite-scroll="nextPage"-->
<!--      :infinite-scroll-immediate="false"-->
<!--      :infinite-scroll-delay="100"-->
<!--    >-->
      <el-option
        v-for="item in options"
        :key="item"
        :label="item"
        :value="item"
      >
      </el-option>
<!--    </div>-->
  </el-select>

</template>

<script>


import {paginator} from "@/mixins/paginator";
import requestSender from "@/api/base/requestSender";

export default {
  name: "smz-user-unique-prop-values-in-procedure-select",
  mixins: [paginator],
  components: {},
  props: {
    value: {},
    size: {type: String, default: ''},
    placeholder: {type: String, default: ''},
    multiple: {type: Boolean, default: false},
    filterable: {type: Boolean, default: false},

    prop: {type: String, required: true},
    // procedureId: {type: Number, required: true},
    // ownerId: {type: Number, required: true},
  },

  data() {
    return {
      loading: false,
      query: null,
      page: 1,
      options: [],
    }
  },
  mounted() {
    // this.remoteMethod()
  },
  watch: {},
  computed: {
    localValue: {
      get: function () {
        return this.value
      },
      set: function (newValue) {
        this.selected(newValue)
      }
    }
  },
  methods: {
    remoteMethod(query) {
      this.query = query;
      // this.page = 1;
      this.options = [];
      this.getData();
    },

    nextPage(){
      this.page++;
      this.getData();
    },

    getData() {
      // if( this.paginator.pageCount && this.page > this.paginator.pageCount ){
      //   return;
      // }
      this.loading = true;
      requestSender('get', 'compensation-procedure/search-users-prop-values-in-procedure', {
        search: this.query,
        prop: this.prop,
        owner_id: this.$route.params.owner_id,
        procedure_id: this.$route.params.procedure_id,
      })
        .then(data => {
          // this.options = this.options.concat(data.users);
          this.options = data.values;
          // this.paginatorFill(paginator);
        })
        .finally(() => {
          this.loading = false;
        })
    },

    selected(value) {
      this.$emit('input', value);
      this.$emit('selected', value)
    }
  },

}
</script>


<style scoped>

</style>